import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(front)": [4,[2]],
		"/(front)/abonnement-terminee": [5,[2]],
		"/admin": [~24,[3]],
		"/admin/codes-promos": [~25,[3]],
		"/admin/commandes": [~26,[3]],
		"/admin/livraison": [~27,[3]],
		"/admin/menus": [~28,[3]],
		"/admin/produits": [~29,[3]],
		"/auth/login": [30],
		"/auth/signup": [31],
		"/(front)/checkout": [~6,[2]],
		"/(front)/commande-terminee": [~7,[2]],
		"/(front)/conditions-generales-de-vente": [8,[2]],
		"/(front)/contact": [~9,[2]],
		"/(front)/le-shop": [10,[2]],
		"/(front)/le-shop/[slug]": [~11,[2]],
		"/(front)/menus-du-moment": [~12,[2]],
		"/(front)/mes-commandes": [13,[2]],
		"/(front)/mes-magazines": [14,[2]],
		"/(front)/mes-magazines/[slug]": [~15,[2]],
		"/(front)/mon-compte": [16,[2]],
		"/(front)/nos-cartes-cadeaux": [~17,[2]],
		"/(front)/nos-cartes-cadeaux/[slug]": [~18,[2]],
		"/(front)/nos-formules": [19,[2]],
		"/(front)/nos-formules/[slug]": [~20,[2]],
		"/(front)/nos-magazines": [~21,[2]],
		"/(front)/nos-magazines/[slug]": [~22,[2]],
		"/(front)/politique-de-confidentialite": [23,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';